<template>
<div class="wrapper">
  <!-- 访客信息 -->
  <el-card class="card-1">
    <!-- 标题 -->
    <div class="header"><h2>访客信息</h2></div>
    <!-- ======================主体表格区域==================== -->
    <div class="item-wrap" >
      <el-table :data="tableData" border style="width: 100%;" >
        <el-table-column type="index" width="40"></el-table-column>
        <el-table-column property="ip"  label="IP地址" width="170"></el-table-column>
        <el-table-column property="area" label="地区" width="170"></el-table-column>
        <el-table-column property="os" label="操作系统" width="170"></el-table-column>
        <el-table-column property="isp" label="运营商" width="170"></el-table-column>
        <el-table-column property="browser" label="浏览器" width="170"></el-table-column>
        <el-table-column property="creTime" label="访问时间" width="200"></el-table-column>
        <el-table-column property="visitTimes" label="访问次数"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-wrap">
        <!--handleSizeChange和handleCurrentChange页面监听方法，当页面发生变化时执行-->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum" :page-sizes="[5, 9 , 15, 20]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" class="page">
        </el-pagination>
      </div>
    </div>
  </el-card>
</div>
</template>


<script>
export default {
  name : 'VisitorInfo' ,
  data(){
    return{
      tableData: [], // 表格展示的数据
      total:0 , 
      queryInfo:{    // 查询信息实体VO类
        id:"" ,
        ip:"" , 
        area:"" ,   
        os:"" , 
        isp:"" , 
        browser:"" , 
        creTime:"" , 
        visitTimes:"" , 
        pageNum: 1 ,   // 设定起始页
        pageSize: 9    // 每页显示的数量
      } , 
    }
  } , 
  methods:{
    // 获取所有访客信息
    getVisitorsList(){
      const _this = this ; 
      // this.queryInfo = this.ruleForm ; 
      this.$axios.post("/getVisitorsListManage"  ,this.queryInfo).then(res => {
        this.tableData = res.data.data.data ;
        this.total = res.data.data.numbers ; 
        // console.log(res);
      }) ; 
    } , 
    // 分页组件中的 页面监听方法，当显示最大数量发生变化时执行
    handleSizeChange(newSize){  
      this.queryInfo.pageSize = newSize ; 
      this.getVisitorsList() ;    // 发生变化后重新加载列表
    } ,
    // 分页组件中的方法，pageNum触发动作，当页码发生变化时执行
    handleCurrentChange(newPage){
      this.queryInfo.pageNum = newPage ; 
      this.getVisitorsList() ; 
    }  , 
  } , 
  created(){
    this.getVisitorsList() ; 
  }
}
</script>


<style scoped>
/* 标题 */
.header{
  font-size: 1rem;
  border-bottom: 1px solid rgb(219, 218, 218);
  box-sizing: content-box;
}
/* 主体表格 */
.item-wrap{
  margin-top: 2rem;
}
.page{
  margin-top: .9rem;
}
</style>